export interface ImageDimension {
  readonly width: number;
  readonly height: number;
}

export const DIMENSION_XL: ImageDimension = { width: 1920, height: 2000 };
export const DIMENSION_M: ImageDimension = { width: 1439, height: 1750 };
export const DIMENSION_S: ImageDimension = { width: 787, height: 1250 };
export const DIMENSION_XS: ImageDimension = { width: 374, height: 1250 };

const addParameters = (url: string, width: number) => {
  const base = url.startsWith('/') ? 'https://www.audi.com' : undefined;
  const urlObj = new URL(url, base);

  urlObj.searchParams.set('auto', 'webp');
  urlObj.searchParams.set('width', String(width));

  const strUrl = urlObj.toString();
  if (base && base.length > 0) {
    return strUrl.substring(base.length);
  }
  return strUrl;
};

export const toSrc = (url: string, width: number) => {
  try {
    return addParameters(url, width);
  } catch {
    return url;
  }
};

export const toSrcSetPartial = (url: string, width: number) => {
  try {
    return `${addParameters(url, width)} ${width}w, ${addParameters(url, width * 2)} ${width * 2}w`;
  } catch {
    return url;
  }
};
