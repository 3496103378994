/* eslint-disable react/display-name */
import { createElement, ReactNode } from 'react';
import { Logger } from '@feature-hub/core';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import type { LocaleServiceConfig } from '@volkswagen-onehub/locale-service/dist';
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@oneaudi/vue-formatter-service';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
  getConsumptionsAndEmissions,
  FootnoteReferenceV2,
} from '@oneaudi/feature-app-utils';
import { MappingProps, mapToTeaserProps } from './mapToTeaserProps';
import { ParallaxTeaserProps, WltpProps } from '../components/ParallaxTeaser';
import { Content } from '../EditorContentTypes';

export async function createInitialState(
  content: Content,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger?: Logger,
) {
  const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
    content.legalData.wltpKeys,
    vueFormatterService,
    localeService as LocaleServiceConfig,
    logger,
  );
  return consumptionsAndEmissionsValues;
}

export function enhanceInitialState(
  consumptionsAndEmissionsValues: ConsumptionsAndEmissions[],
  content: Content,
): ParallaxTeaserProps {
  const mappingProps: MappingProps = {
    content,
    headline: renderTextWithFootnotesReferencesV2(content.headline),
    copy: renderTextWithFootnotesReferencesV2(content.copy),
    wltpData: [],
  };

  if (content.legalData?.additionalText) {
    mappingProps.additionalLegalText = renderTextWithFootnotesReferencesV2(
      content.legalData.additionalText,
    );
  }

  consumptionsAndEmissionsValues.forEach((cae) => {
    const mappingData: WltpProps = {
      formattedConsumption: undefined,
      formattedEmission: undefined,
      formattedCo2Class: undefined,
      formattedDischargedCo2Class: undefined,
      formattedDischargedConsumption: undefined,
    };

    Object.keys(mappingData).forEach((key) => {
      /* eslint-disable no-prototype-builtins */
      if (cae.hasOwnProperty(key)) {
        const caeValue = cae[key as keyof typeof cae] as string | undefined;

        if (caeValue && caeValue.length > 0) {
          /* eslint-enable no-prototype-builtins */
          mappingData[key as keyof WltpProps] = renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(caeValue),
          );
        }
      }
    });

    if (Object.values(mappingData).some((value) => value !== undefined)) {
      mappingProps.wltpData.push(mappingData);
    }
  });

  return mapToTeaserProps(mappingProps);
}

interface SerializedWltpProps {
  formattedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown>)[] | undefined;
}

export const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[],
): undefined | string | ReactNode[] => {
  if (!deserializedProperty || typeof deserializedProperty === 'string') {
    // if it's undefined or a string it doesn't contain any footnotes. Nothing to do here
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReference /> react component
    return createElement(FootnoteReferenceV2, serializedReactNode.props as undefined);
  });
};

const deserializeReactNodeArrayInWltpData = (wltpData: SerializedWltpProps[]) => {
  return wltpData.map(({ formattedConsumption, formattedEmission }) => {
    return {
      formattedConsumption: deserializeReactNodeArray(formattedConsumption),
      formattedEmission: deserializeReactNodeArray(formattedEmission),
    };
  });
};

/**
 * Helper function to deserialize the state of the feature app.
 * It converts serialized ReactNodeArray Entries that aren't string to FootnoteReference components
 * @param state
 */
export function deserializeState(state: string): ParallaxTeaserProps {
  const props = JSON.parse(state);
  return {
    ...props,
    headline: deserializeReactNodeArray(props.headline),
    copy: deserializeReactNodeArray(props.copy),
    legalData: {
      ...props.legalData,
      additionalText: deserializeReactNodeArray(props.legalData.additionalText),
      wltpData: deserializeReactNodeArrayInWltpData(props.legalData.wltpData),
    },
  };
}
