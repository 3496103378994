import { Asset, ContentReference, isContentFragment } from '@oneaudi/falcon-tools';
import { RenderMode } from '@oneaudi/render-mode-service';
import { Content, HeadlessContent, Link } from '../EditorContentTypes';

export const mapLinks = (content: HeadlessContent) => {
  const links: Link[] = [];
  if (content.fields.links_0_href && content.fields.links_0_text) {
    links.push({
      href: content.fields.links_0_href,
      text: content.fields.links_0_text,
    });
  }
  if (content.fields.links_1_href && content.fields.links_1_text) {
    links.push({
      href: content.fields.links_1_href,
      text: content.fields.links_1_text,
    });
  }
  return links;
};

function isFalconContent(content: unknown): content is HeadlessContent {
  return Boolean(
    isContentFragment(content) &&
      'headline' in content.fields &&
      content.fields.headline &&
      'copy' in content.fields &&
      content.fields.copy &&
      'images_imageXS' in content.fields &&
      content.fields.images_imageXS &&
      'images_imageS' in content.fields &&
      content.fields.images_imageS &&
      'images_imageM' in content.fields &&
      content.fields.images_imageM &&
      'images_imageXL' in content.fields &&
      content.fields.images_imageXL &&
      'linkType' in content.fields &&
      content.fields.linkType &&
      'legalData_theme' in content.fields &&
      content.fields.legalData_theme,
  );
}

export const mapHeadlessContent = (
  content: Content | HeadlessContent,
  renderMode: RenderMode,
): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    console.log('initialContent: ', content);
    if (!isFalconContent(content)) {
      return {
        headline: 'PLACEHOLDER headline',
        copy: 'PLACEHOLDER copy',
        links: [
          {
            href: 'https://www.audi.de/de/brand/de/elektromobilitaet.html',
            text: 'PLACEHOLDER text 1',
          },
          {
            href: 'https://www.audi.de/de/brand/de.html',
            text: 'PLACEHOLDER text 2',
          },
        ],
        images: {
          imageXS: {
            assetValue: 'https://dummyimage.com/787x1250',
          },
          imageS: {
            assetValue: 'https://dummyimage.com/786x1280',
          },
          imageM: {
            assetValue: 'https://dummyimage.com/1053x1280',
          },
          imageXL: {
            assetValue: 'https://dummyimage.com/1920x1280',
          },
          altText: 'PLACEHOLDER alt text',
        },
        linkType: 'link',
        legalData: {
          theme: 'light',
          wltpKeys: [],
          additionalText: '',
        },
      };
    }
    const normalizedContent: Content = {
      headline: providePlaceHolderText(
        content.fields.headline,
        'Lorem ipsum dolor sit amet',
        renderMode,
      ),
      copy: providePlaceHolderText(
        content.fields.copy,
        'Lorem ipsum dolor sit amet consectetur adipiscing elit eget vehicula, magnis laoreet elementum eleifend diam feugiat maecenas nisi inceptos eu, euismod cursus sodales sociosqu eros convallis nisl interdum.',
        renderMode,
      ),
      links: mapLinks(content),
      linkType: content.fields.linkType,
      images: {
        imageXS: {
          assetValue: providePlaceHolderImage(
            content.fields.images_imageXS,
            'https://dummyimage.com/787x1250',
            renderMode,
          ),
        },
        imageS: {
          assetValue: providePlaceHolderImage(
            content.fields.images_imageS,
            'https://dummyimage.com/786x1280',
            renderMode,
          ),
        },
        imageM: {
          assetValue: providePlaceHolderImage(
            content.fields.images_imageM,
            'https://dummyimage.com/1053x1280',
            renderMode,
          ),
        },
        imageXL: {
          assetValue: providePlaceHolderImage(
            content.fields.images_imageXL as Asset,
            'https://dummyimage.com/1920x1280',
            renderMode,
          ),
        },
        altText: content.fields.images_altText ?? '',
      },
      legalData: {
        theme: content.fields.legalData_theme,
        additionalText: content.fields.legalData_additionalText,
        wltpKeys: [],
      },
    };
    if (content.fields.legalData_wltpKeys.length > 0) {
      content.fields.legalData_wltpKeys.forEach((wltpKey) => {
        normalizedContent.legalData.wltpKeys.push(wltpKey);
      });
    }
    console.log('normalizedContent: ', normalizedContent);

    return normalizedContent;
  }
  return content as Content;
};

/**
 * If a field is has no content but we are in a the RenderMode.EDIT
 * Then we set a placeholder image to make the editing in the UE possible.
 * Otherwise the element may not be shown, which makes it impossible to edit it in the
 * UE
 *
 * @param field
 * @param placeholder
 * @param renderMode
 * @returns
 */
function providePlaceHolderImage(
  field: ContentReference,
  placeholder: string,
  renderMode: RenderMode,
) {
  if (!field && renderMode === RenderMode.EDIT) {
    return placeholder;
  }

  return (field as Asset).path;
}

/**
 * If a field is has no content but we are in a the RenderMode.EDIT
 * Then we set a placeholder text to make the editing in the UE possible.
 * Otherwise the element may not be shown, which makes it impossible to edit it in the
 * UE
 *
 * @param field
 * @param placeholder
 * @param renderMode
 * @returns
 */
function providePlaceHolderText(field: string, placeholder: string, renderMode: RenderMode) {
  if (!field && renderMode === RenderMode.EDIT) {
    return placeholder;
  }

  return field;
}
